#key_header {
    font: 700 28px/39.2px "Poppins";
    color: var(--pure-white-color);
    padding-bottom: 40px;
}

.card_custom_header {
    font: 600 24px/33.6px "Poppins";
    color: var(--pure-white-color);
    margin-bottom: 12px;
}

.card_custom_text {
    font: 400 17.4px/25.2px "Poppins";
    color: var(--pure-white-color);
}

.kh_card {
    height: 320px;
    min-width: 350px;
    padding: 40px 18px 40px 18px;
    gap: 10px;
    background: linear-gradient(180deg, #1E1637 -8.36%, #1C1230 145.07%);
    border-bottom: 2px solid #110b1d !important;
    border-left: #170c24 !important;
    border-radius: 16px;
    border-right: #170c24 !important;
    border-top: 2px solid #3a3364 !important;
}

.kh_row {
    row-gap: 40px !important;
}
 
.kh_card_div {
    min-width: 360px !important;
}


/* Terms Key Highlights styles */
#kh_row_2{
    margin-top: 40px;
}
#terms_key_section .fs_first_row {
    height: 19.2em !important;
}

#terms_key_section .fs_second_row {
    height: 24.8em !important;
}

#terms_key_section .kh_card {
    padding: 40px 20px 40px 20px;
}

#terms_key_section .kh_fs_second_row{
    height: 26.5em !important; 
}

#terms_key_section .fs_card{
    height: auto !important;
    margin-top: 40px;
    padding-left: 21px !important;
    padding-right: 21px !important;
}

#terms_key_section .custom_terms_gap{
    padding-bottom: 18px;
}

@media screen and (min-width: 1400px) {
    .kh_card {
        height: 300px !important;
    }
    #terms_key_section .fs_first_row {
        height: 18em !important;
    }
    #terms_key_section .fs_second_row {
        height: 23.7em !important;
    }
  }

@media screen and (min-width: 991px) and (max-width: 1199px) {
    .kh_card_div {
        width: 50%;
    }

    .kh_card {
        height: auto !important;
    }

    .mob_first_row_card {
        height: 260px !important;
    }

    .tab_cs{
        height: 291px !important;
    }

    #terms_key_section .tb_fs_row {
        height: 15.2em !important;
    }

    #terms_key_section .tb_sd_row {
        height: 23.4em !important;
    }

    #terms_key_section .tb_td_row {
        height: 21.4em !important;
    }

    #kh_row_2 .kh_card_div{
        width: auto !important;
    }
    #terms_key_section .kh_fs_second_row{
        height: auto !important; 
    }
}

@media screen and (max-width: 991px) {
    .kh_row {
        display: flex !important;
        justify-content: center !important;
    }

    .kh_card_div {
        min-width: 348px !important;
    }

    .kh_card {
        height: auto !important;
        min-width: auto !important;
    }

    .mob_first_row_card {
        height: 315px !important;
    }

    #terms_key_section .tb_fs_row {
        height: 17.6em !important;
    }

    #terms_key_section .tb_sd_row {

        height: 25.95em !important;
    }

    #terms_key_section .tb_td_row {
        height: 23.8em !important;
    }

    #kh_row_2 .kh_card_div{
        width: auto !important;
    }
    #terms_key_section .kh_fs_second_row{
        height: auto !important; 
    }

}


@media screen and (max-width: 767px) {
    .kh_card_div {
        width: 100%;
    }

    .kh_card {
        height: auto !important;
        min-width: auto !important;
    }

    .kh_row {
        display: flex !important;
        justify-content: center !important;
    }
    #terms_key_section .tb_fs_row {
        height: auto !important;
    }

    #terms_key_section .tb_sd_row {

        height: auto !important;
    }

    #terms_key_section .tb_td_row {
        height: auto !important;
    }
}

@media screen and (max-width: 430px) {
    #key_header {
        font-size: 24px !important;
        text-align: center !important;
    }

    .card_custom_header {
        font-size: 20px !important;
        line-height: 28px !important;
    }

    .kh_card {
        gap: 5px !important;
        min-width: auto !important;
    }
    .kh_row {
        row-gap: 16px !important;
    }

    .card_custom_text {
        font-size: 14px !important;
        line-height: 19.6px !important;
    }

    #privacy_details_info {
        padding-top: 80px;
    }
     
    .terms_head {
        font-size: 28px !important;
    }

    #kh_row_2, #terms_key_section .fs_card{
        margin-top: 16px;
    }

    #terms_key_section .custom_terms_gap{
        padding-bottom: 10px !important;
    }
}