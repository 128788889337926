#featured_in_section .img-wrapper {
  max-width: 100%;
  height: 65vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

#featured_in_carousel_controls .card{
  background-color: transparent;
  border: none;
}

#featured_in_carousel_controls .carousel-control-prev,
#featured_in_carousel_controls .carousel-control-next {
  width: 6vh;
  height: 6vh;
  background-color: none !important;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}
#featured_in_carousel_controls .carousel-control-prev:hover,
#featured_in_carousel_controls .carousel-control-next:hover {
  opacity: 0.8;
}

#featured_in_carousel_controls .carousel-control-prev{
  left: -20px;
}
#featured_in_carousel_controls .carousel-control-next{
  right: -20px;
}

#featured_in_carousel_controls .carousel-indicators{
  display: none;
}

#featured_in_carousel_controls .blog_post_cards_div{
  /* width: 100%; */
  padding-left: 12px;
  padding-right: 12px;
}

#featured_in_carousel_controls{
  padding: 32px 32px 32px 32px;
  border: rgb(17, 11, 29) solid 2px !important;
  border-radius: 48px;
  padding-top: 52px !important;
}


#featured_in_carousel_controls .carousel-control-next-icon{
  background-image: url('../images/carousel_next_arrow.svg');
  width: 4rem;
  height: 4rem;
}
#featured_in_carousel_controls .carousel-control-prev-icon{
  background-image: url('../images/carousel_prev_arrow.svg');
  width: 4rem;
  height: 4rem;
}


#featured_in_carousel_controls .carousel-indicators .active {
background-color: white;
}
.higher_res{
  display: block !important;
}

@media screen and (min-width: 1400px) {
.lower_res{
  display: none !important;
}
.mobile_res{
  display: none !important;
}
}

.carousel-control-next_v1{
  opacity: 2 !important;
}

#featured_in_carousel_controls .blog_post_title {
  font-size: 20px !important;
}

@media screen and (max-width: 1199px) {
  #featured_in_carousel_controls .blog_post_title {
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 767px) {
  #featured_in_carousel_controls .blog_post_title {
    font-size: 0.9rem !important;
  }
   .higher_res{
    display: none !important;
  }
  .lower_res{
    display: block !important;
  }
}

@media screen and (max-width: 576px) {
  #featured_in_carousel_controls .carousel-control-prev{
    left: -11px !important;
  }
  #featured_in_carousel_controls .carousel-control-next{
    right: -11px !important;
  }
  .lower_res{
    display: none !important;
  }
  .mobile_res_2{
    display: block !important;
  }
  #featured_in_carousel_controls .blog_post_title {
    font-size: 0.9rem !important;
    height: auto !important;
  }
}

@media screen and (max-width: 430px) {
#featured_in_carousel_controls{
  padding: 36px 20px 20px 20px !important;
  min-height: 27.7em !important;
}
.mobile_res_2{
  display: none !important;
}
.mobile_res{
  display: block !important;
} 

#featured_in_container{
  padding-bottom: 0px !important;
}

#featured_in_carousel_controls .carousel-control-next-icon, #featured_in_carousel_controls .carousel-control-prev-icon{
  width: 2.4rem;
}
#featured_in_carousel_controls .carousel-control-prev{
  left: -13px !important;
}
#featured_in_carousel_controls .carousel-control-next{
  right: -13px !important;
}
#featured_in_carousel_controls .blog_post_title {
  font-size: 1.2rem !important;
}
}

@media screen and (max-width: 378px) {
  #featured_in_carousel_controls .blog_post_title {
    font-size: 0.99rem !important;
  }
}
