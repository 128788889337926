/* featured-in section styles */
#featured_in_container {
  padding-top: 96px !important;
}

#feature_in_section{
  background: radial-gradient(46.59% 46.59% at 50% 63.22%, #27214B 0%, #170C24 100%) !important;
}

#featred_in_header {
  font: 700 3rem/57.6px "Poppins";
  color: var(--pure-white-color);
  padding-bottom: 48px !important;
}

@media screen and (max-width: 767px) and (min-width: 577px) {
  .featured_logs, .featured_logs img {
    width: 100% !important;
  }
  #featured_in_carousel_controls .carousel-item:first-child .blog_post_cards_div:nth-child(2) .featured_logs img {
    width: auto !important;
  }
}

@media screen and (max-width: 750px){
  #featured_in_container {
    padding-top: 32px !important;
    padding-bottom: 60px !important;
}
}

@media screen and (max-width: 430px) {
  #feature_in_section{
    padding: 0px !important;
  }

  #featred_in_header {
    font-size: 24px !important;
    padding-bottom: 24px !important;
  }
  #featured_in_container {
    padding-top: 55px !important;
  }
}