/* book your aurm safe section styles. */
.book_your_aurm_safe_section {
    background: radial-gradient(37.98% 37.98% at 50% 54.62%, #27214B 0%, #170C24 100%);
    padding: 96px 0px 96px 0px !important
}

.book_your_aurm_safe_primary_text {
    font: 700 3rem/57.6px "Poppins";
    letter-spacing: 0;
}

.white_text {
    color: var(--pure-white-color);
}

.book_your_aurm_safe_secondary_text {
    font: 500 1.5rem/33.6px "Poppins";
    letter-spacing: 0;
    color: var(--light-sky-blue);
    padding-top: 20px;
}

.book_your_aurm_safe_img_div{
    gap : 1.1rem !important;
}

@media screen and (max-width: 991px) {
    .book_your_aurm_safe_primary_text {
        font-size: 2.25rem !important;
    }

    .book_your_aurm_safe_secondary_text {
        margin-top: -9px !important;
        font-size: 18px !important;
    }
}

@media screen and (max-width: 767px) {
    .book_your_aurm_safe_primary_text {
        font-size: 1.625rem !important;
    }
    .book_your_aurm_safe_secondary_text {
        font-size: 16px !important;
        margin-top: -16px !important;
    }
}

@media screen and (max-width: 541px) {
    .book_your_aurm_safe_primary_text {
        font-size: 1.5rem !important;
        margin-top: 1rem !important;
    }

    .book_your_aurm_safe_secondary_text {
        font-size: 1rem !important;
        padding-top: 7px !important;
    }
    .book_your_aurm_safe_playstore_imgs {
        width: 11rem !important;
        height: 4rem !important;
    }
    .book_your_aurm_safe_section{
        padding: 55px 0px 55px 0px !important;
    }
}

@media screen and (max-width:452px) {
    .book_your_aurm_safe_primary_text {
        font-size: 25.2px !important;
        line-height: 30.2px !important;

    }
    .book_your_aurm_safe_playstore_imgs {
        width: 160.65px !important;
        height: 47.33px !important;
    }
    .book_your_aurm_safe_secondary_text {
        font-size: 18.8px !important;
        line-height: 26.5px !important;
        padding-top: 24px !important;
    }
    .book_your_aurm_safe_img_div{
        margin-top : 20px !important;
        gap : 17px !important;
    }
    .book_your_aurm_safe_section{
        padding: 1rem 16px 0px 16px !important;
    }
}

@media screen and (max-width:430px) {
    .book_your_aurm_safe_primary_text {
        font-size: 24px !important;
        line-height: 28.8x !important;
    }
    .book_your_aurm_safe_secondary_text {
        /* margin-top: -9px !important; */
        font-size: 18px !important;
        line-height: 25.2px !important;
        padding-top: 24px !important;
    }
    .book_your_aurm_safe_playstore_imgs {
        /* width: 152.84px !important;
        height: 45.03px !important; */
        width: 152.84px !important;
        height: 47.03px !important;
    }
    .book_your_aurm_safe_img_div{
        margin-top : 20px !important;
        gap : 16.38px !important;
    }
    .book_your_aurm_safe_section{
        padding: 1rem 16px 0px 16px !important;
    }
}

@media screen and (max-width:399px) {
    .book_your_aurm_safe_primary_text {
        font-size: 22.5px !important;
        line-height: 26.7x !important;
    }
    .book_your_aurm_safe_secondary_text {
        font-size: 16.6px !important;
        line-height: 23.4px !important;
        padding-top: 24px !important;
    }
    .book_your_aurm_safe_playstore_imgs {
        /* width: 141.82px !important;
        height: 41.78px !important; */
        width: 145.82px !important;
        height: 41.78px !important;
    }
    .book_your_aurm_safe_img_div{
        margin-top : 20px !important;
        gap : 15.2px !important;
    }
    .book_your_aurm_safe_section{
        padding: 1rem 14.8px 0px 14.8px !important;
    }
}

@media screen and (max-width:374px) {
    .book_your_aurm_safe_primary_text {
        font-size: 21px !important;
        line-height: 24x !important;
    }
}
