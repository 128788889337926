/* Footer section styles */
.footer_text_gradient_color {
    background:#B8D5E0;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#footer_section {
    margin-top: 8em !important;
    width: 98%;
    height: 100%;
    padding-top: 64px;
    padding-bottom: 48px;
    background: var(--voilate-radial-background);
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    overflow: hidden;
    border-top: 2px #4F4589 solid;
    gap: 4rem;
}

#footer_section .footer_container {
    width: 100%;
    height: 112.54px;
    padding-left: 32px;
    padding-right: 32px;
    gap: 48px;
}

#footer_section .footer_logo_div {
    width: 148px;
    height: 53.54px;
    gap: 8.50px;
}

#footer_section .menu_container {
    gap: 32px;
}

#footer_section .menu_text {
    font: 400 1.125rem/27px "Poppins";
}

#footer_section .footer_bottom {
    width: 87%;
    height: auto;
    padding: 16px 0;
    border-top: 1px #004870 solid;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: 3rem;
    margin-right: 3em;
}

#footer_section .footer_links {
    gap: 16px;
    font: 400 1rem/24px "Poppins";
    word-wrap: break-word;
    padding-top: 4px !important;
}

#footer_section .link_no_display {
    text-decoration: none;
    color: inherit;
}

#footer_section .menu_text:hover {
    cursor: pointer !important;
}

@media  screen and (max-width: 1090px) {
    .flex_wrap_options {
        flex-wrap: wrap !important;
    }
    #footer_section .footer_container{
        height: 9em !important;
    }
    #footer_section .footer_link{
        text-align: center !important;
    }
}

@media  screen and (max-width: 580px) {
    .flex_wrap_options {
        flex-wrap: wrap !important;
    }
    #footer_section .footer_container{
        height: 13em !important;
    }
}

@media (max-width: 496px){
    #footer_section {
        width: 92% !important;
        margin-left: 16px !important;
        margin-right: 16px !important;
    }
    #footer_section .footer_container {
        gap: 32px;
        height: 8em !important ;
    }
    .flex_wrap_options {
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        padding: opx 16px 0px 16px;
    }
    
    #footer_section .menu_container {
        row-gap: 15px !important;
        column-gap: 38px !important;
    }
     #footer_section .menu_text{
        font-size: 16px !important;
        line-height: 24px !important;
    }

    #footer_section .footer_bottom {
        width: 84%;
        padding: 32px 0 0 0;
    }
    
    #footer_section .footer_link {
        font-size: 12px !important;
        line-height: 24px;
        padding-left:2.5px !important;
        width: 100%;
    }
    
}

@media (max-width: 428px){
    #footer_section .footer_link {
        font-size: 11.5px !important;
    }

    #footer_section .menu_container {
        row-gap: 13px !important;
        column-gap: 29px !important;
    }

    #footer_section .footer_container {
        height: 8em !important ;
    }
}

@media (max-width: 410px){
    #footer_section .footer_link {
        font-size: 11px !important;
    }
}

@media screen and (max-width: 408px){
    #footer_section .footer_container {
        height: 8em !important ;
    }
    #footer_section .menu_container {
        row-gap: 18px !important;
        column-gap: 17px !important;
    }
   
}

@media (max-width: 396px){

    #footer_section .footer_link {
        font-size: 10.5px !important;
    }
    #footer_section .menu_text{
        font-size: 13.95px !important;
        line-height: 24px !important;
    }
}

@media (max-width: 376px){
    #footer_section .footer_link {
        font-size: 9.9px !important;
    }
}