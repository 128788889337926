/* watch video styles */

.video_section_main {
    background: var(--faq-bg-color) !important;
    margin-top: -3px !important;
    padding-top: 50px;
}

#watch_video {
    width: 100%;
}

#watch_video {
    border-radius: 1em !important;
    overflow: hidden !important;
}

#watch_video_top_img_div {
    position: relative;
    z-index: 1;
    background: transparent !important;
    width: min-content;
    margin-bottom: -9.5em !important;
    margin: auto;
    z-index: 1;
}

#watch_video_top_mask_img {
    height: 96px;
}

#watch_video_text {
    font: 700 3rem/3.6rem "Poppins" !important;
    color: var(--pure-white-color);
    position: relative;
    z-index: 999 !important;
    margin-bottom: 0.2rem;
}

#watch_video_sub_text {
    background: #DB2349;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.eligibility_btn_div {
    margin-top: -1.625rem !important;
    z-index: 3;
    margin: auto;
}

#eligibility_btn {
    color: var(--pure-white-color);
    font-weight: 600;
    font-family: Poppins;
    line-height: 120%;
    font-size: 1.5rem;
    border: transparent;
    position: relative;
    width: auto !important;
    padding-left: 3.125rem;
    padding-right: 3.125rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 3.75rem;
    background: linear-gradient(0deg, #9D0154 -13.28%, #EA1855 110.94%);
}



#play_button_svg {
    width: 80px;
    height: 80px;
}


.video_model {
    max-width: 80% !important;
}

.video_modal_content {
    background: transparent !important;
    border: none !important;
}

.watch-video-modal-class {
    margin-top: -5px !important;
}

#video_top_layer {
    max-width: 40% !important;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -1%);
    z-index: 2;
}
#video_bottom_layer{
    max-width: 30% !important;
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 2;
}

/* All media Queries start here*/
@media screen and (min-width: 1200px) {
    #watch_video_text {
        top: 1.45em !important;
    }
}

@media (min-width: 1441px) and (max-width: 1800px) {
    #watch_video_text {
        top: 1.5em !important;
    }
    #video_top_layer{
        max-width: 60% !important;
        top: 0%;
    }
    #video_bottom_layer{
        max-width: 45% !important;
    }
}


@media (min-width: 1200px) and (max-width: 1440px) {
    #watch_video_text {
        top: 1.5em !important;
    }
    #video_top_layer{
        max-width: 60% !important;
        top: 0%;
    }
    #video_bottom_layer{
        max-width: 45% !important;
    }
}

@media (min-width: 1060px) and (max-width: 1199px) {
    #watch_video_text {
        top: 4.2rem !important;
        font-size: 2.4rem !important;
    }
    #video_top_layer{
        max-width: 60% !important;
        top: 0%;
    }
    #video_bottom_layer{
        max-width: 45% !important;
    }
}

@media screen and (max-width: 1061px) {
    #watch_video_top_mask_img {
        /* height: 73px; */
        height: 83px;
    }

    #watch_video_top_img_div {
        /* margin-bottom: -8.1em !important; */
        margin-bottom: -8.7em !important;
    }

    #watch_video_text {
        font-size: 2rem !important;
        top: 1.755em;
    }
    #video_top_layer{
        max-width: 65% !important;
        top: 0%;
    }
    #video_bottom_layer{
        max-width: 50% !important;
    }

    #play_button_svg {
        width: 80px;
        /* Adjust the width for smaller screens */
        height: 80px;
        /* Adjust the height for smaller screens */
    }
}

@media (min-width: 801px) and (max-width: 900px) {
    #video_top_layer{
        top: -2px;
    }
    #video_bottom_layer{
        max-width: 50% !important;
    }
}

@media screen and (max-width: 800px) {
    #watch_video_top_mask_img {
        /* height: 50px; */
        height: 58px;
    }

    #watch_video_top_img_div {
        /* margin-bottom: -6.71em !important; */
        margin-bottom: -7.2em !important;
    }

    #watch_video_text {
        font-size: 1.5rem !important;
        top: 1.96em;
    }
    #video_top_layer{
        max-width: 60% !important;
        top: -2px;
    }
    #video_bottom_layer{
        max-width: 40% !important;
    }
    #eligibility_btn{
        font-size: 0.75rem;
        margin-top: 0.5rem !important;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
    }
    .button_dot{
        margin-top: 0.5rem !important;
    }

    #play_button_svg {
        width: 60px;
        /* Adjust the width for smaller screens */
        height: 60px;
        /* Adjust the height for smaller screens */
    }

}

@media (max-width:670px){
    #eligibility_btn{
        font-size: 0.6rem;
        margin-top: 0.4rem;
        padding-left: 1.65rem;
        padding-right: 1.65rem;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;

    }

}


@media (max-width: 576px) {
    #play_button_svg {
        width: 50px;
        /* Adjust the width for smaller screens */
        height: 50px;
        /* Adjust the height for smaller screens */
    }

    #watch_video_text {
        font-size: 1.3rem !important;
        top: 2.3em;
    }
    #video_top_layer{
        max-width: 60% !important;
        top: 0%;
    }
    #video_bottom_layer{
        max-width: 45% !important;
    }

    #eligibility_btn{
        font-size: 0.6rem;
        margin-top: 0.5rem !important;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    
    .video_model {
        margin: auto !important;
    }
}

@media screen and (max-width: 550px) {
    #watch_video_top_mask_img {
        /* height: 36px; */
        height: 43px;
    }

    #watch_video_top_img_div {
        /* margin-bottom: -6.2em !important; */
        margin-bottom: -6.2em !important;
    }

    #watch_video_text {
        /* font-size: 26px !important; */
        font-size: 1.2rem !important;
        margin-bottom: 0.4rem;
        top:2.55em;
    }
    #video_top_layer{
        max-width: 60% !important;
        top: -1%;
    }
    #video_bottom_layer{
        max-width: 45% !important;
    }

    #eligibility_btn {
        font-size: 0.5rem !important;
        margin-top: 0.8rem !important;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 1.35rem;
        padding-right: 1.35rem;
    }
    .button_dot{
        margin-top: 1rem !important;
        width: 1rem !important;
        height: 1rem !important;
    }

    
    
    #play_button_svg {
        width: 50px !important;
        height: 50px !important;
    }
    
}

@media screen and (max-width: 450px) {
    #watch_video_text {
        /* font-size: 26px !important; */
        font-size: 1rem !important;
        margin-bottom: 0rem;
    }
    #video_top_layer{
        max-width: 60% !important;
        top: -1%;
    }

    #play_button_svg {
        width: 35px !important;
        height: 35px !important;
    }
    #eligibility_btn {
        font-size: 0.4rem !important;
        margin-top: 0.85rem !important;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        padding-left: 1.12rem;
        padding-right: 1.12rem;
    }
    .button_dot{
        margin-top: 1rem !important;
        width: 1rem !important;
        height: 1rem !important;
    }
}

@media screen and (max-width: 400px) {
    #watch_video_top_mask_img {
        /* height: 27px; */
        height: 34px;
    }

    #watch_video_top_img_div {
        /* margin-bottom: -5.24em !important; */
        margin-bottom: -5.67em !important;
    }

    #watch_video_text {
        font-size: 0.9rem !important;
        margin-bottom: 0rem;
        top:2.7em;
    }

    #video_top_layer{
        top: -1%;
    }

    #watch_video_bottom_mask_img {
        height: 30px;
    }

    #eligibility_btn {
        font-size: 0.4rem !important;
        margin-top: 0.85rem !important;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .button_dot{
        margin-top: 1rem !important;
        width: 1rem !important;
        height: 1rem !important;
    }


    #how_it_works_video_section  {
        margin-top: -1.73em !important;
    }

    
    #play_button_svg {
        width: 30px !important;
        height: 30px !important;
    }
}


/*video_overlay play button styles */
.video_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
}

.play_button_overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}

#play_button {
    border: none;
    cursor: pointer;
    z-index: 4;
}

#video_close_button {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: end;
}

.custom-close-button {
    color: white;
}

.custom-close-button:hover {
    color: white;
}

.custom-close-button:focus {
    border: none !important;
}

@media screen and (max-width: 430px) {
    #video_top_layer{
        display: none !important;
    }

    #watch_video_text{
        font-size: 24px !important;
        top: initial !important;
        padding-bottom: 24px !important;
        line-height: 120%;
        padding-top: 60px !important;
    }

    .watch_btn_dot{
        display: none;
    }

    #video_bottom_layer {
        display: none !important;
    }

    .eligibility_btn_div{
        margin-top: initial !important;
    }

    #eligibility_btn{
        font-size: 14px !important;
        width: 169.363px !important;
        height: 39.997px !important;
        padding: 5px 14.999px !important;
        margin-top: 40px !important;
    }
    
    #watch_video_section{
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 40px;
    }

    .video_model {
        margin: auto !important;
    }
    .video_section_main {
        padding-top: 0px;
    }
}