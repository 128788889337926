/* 
Blog post styles.
 */
 #blog_post_section .blog_post_card {
    border-radius: 16px;
    border: none;
    background: none;
}

#featured_in_container .blog_post_arrow{
    width: 23%;
    text-align: end;
}

#featured_in_container .blog_post_arrow img {
    height: 24px;
    width: 24px;
}

#featured_in_container .blog_post_card {
    border-radius: 16px;
    border: none;
    background: none;
}

.blog_post_title {
    font: 600 1.125rem/28px "Poppins";
    letter-spacing: 0;
    display: flex;
    justify-content: space-between !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 24px !important;
    height: 80px;
}

.image_thumbnail_or_name {
    margin-top: 24px !important;
    align-items: center;
    margin-bottom: 32px;
}

.blog_post_publicher_image {
    padding-right: 12px !important;
}


.blog_post_info {
    font: 400 1.25rem/27px "Poppins";
    letter-spacing: 0;
}

.blog_post_publisher_name {
    font: 600 1rem/24px "Poppins";
    letter-spacing: 0;
}

.blog_post_published_on {
    font: 400 1rem/24px "Poppins";
    letter-spacing: 0;
}

.blog_post_info_published_color {
    color: #B8D5E0;
}

.blog_post_arrow {
    padding-left: 16px;
}
.blogs-image-class{
    border-radius: 16px;
    /* height: 210px !important; */
}

@media screen and(max-width: 1299px) {
    .blog_post_title {
        height: 80px;
    }
}

@media screen and (min-width: 1400px) {
    .blog_post_title {
        margin-bottom: 4px !important;
    }
    .image_thumbnail_or_name {
        margin-top: 4px !important;
    }
    #featured_in_container .blog_post_title {
        margin-bottom: 24px !important;
    }
  }

  @media screen and (max-width: 430px) {
    .blog_post_title {
        font: 600 1.25rem/28px "Poppins";
        margin-top: 20px !important;
    }
    .blog_post_publisher_name {
        font: 600 1rem/24px "Poppins";
        color:  #91CCED !important;
    }
    .blog_post_published_on {
        font: 400 1rem/24px "Poppins" !important;
        color: #91CCED;
    }
    .image_thumbnail_or_name {
        margin-top: 16px !important;
        margin-bottom: 48px !important;
    }
    #featured_in_container .blog_post_arrow {
        width: 30%;
    }
    #featured_in_carousel_controls .image_thumbnail_or_name{
        margin-bottom: 1em !important;
    }
    #featured_in_container .blog_post_title {
        height:80px !important;
    }
  }

  @media screen and (max-width: 378px) {
    #featured_in_carousel_controls .blog_post_title {
      height: 80px !important;
    }
  }