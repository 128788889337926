/* privacy and Refund page styles */
#policy_section {
    background: radial-gradient(59.12% 59.12% at 50% 50%, #27214B 0%, #170C24 100%) !important;
}

#privacy_and_refund_container {
    padding: 176px 30px 96px 30px !important;
}


#privacy_policy_head {
    max-width: 980px !important;
    margin: auto !important;
}

#privacy_container_intro {
    padding-bottom: 0px;
}

#policy_section .privacy_heading {
    font: 600 1rem/24px "Poppins";
    letter-spacing: 0em;
    text-align: center;
    color: rgba(207, 30, 77, 1);
    margin-bottom: 12px;
}

#privacy_policy_main_head {
    font: 700 3rem/57.6px "Poppins";
    letter-spacing: 0em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    padding-bottom: 24px;
}

#policy_intro {
    font: 400 1.25rem/30px "Poppins";
    text-align: center;
    color: rgba(95, 136, 159, 1);
}

.policy_section_sub_heads {
    font: 700 1.75rem/39.2px "Poppins";
    letter-spacing: 0em;
    color: var(--pure-white-color);
    padding-bottom: 20px !important;
}

.policy_sub_section_btm_pd{
    padding-top: 25px;
}

.policy_information {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    opacity: 0.9;
    margin-bottom: 1rem;
}

#terms_section .policy_information{
    line-height: 27px;
}

.list_of_privacy_policy_data {
    list-style-type: disc;
}

/* .policy_div{
     margin-top: 2rem; 
} */

#privacy_details_info {
    padding-top: 96px;
}

@media screen and (max-width: 550px) {
    #privacy_policy_main_head {
        font-size: 30px !important;
    }

    #policy_intro {
        font-size: 18px !important;
    }

    .policy_section_sub_heads {
        font-size: 20px !important;
    }

    .policy_information {
        font-size: 16px !important;
    }
}

@media screen and (max-width: 430px) {
    #privacy_policy_main_head {
        font-size: 24px !important;
        padding-bottom: 16px !important;
    }

    #policy_intro {
        font-size: 13px !important;
        line-height: 19.5px !important;
    }

    #privacy_and_refund_container {
        padding: 96px 0px 60px 0px !important;
    }
    .policy_section_sub_heads {
        font-size: 24px !important;
        line-height: 33.6px !important;
    }

    #policy_section {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }

    #padding_privacy_id {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .policy_information {
        font-size: 14px !important;
        line-height: 21px !important;
    }

    #privacy_footer #footer_section {
        width: 100% !important;
        margin: auto !important;
        margin-top: 46px !important;
    }

}

.under_lined_text {
    text-decoration: underline !important;
}

#footer_section {
    margin-top: 96px !important;
}