/* Testimonial section styles. */
#testimonial_main_section{
    background: #180D25;
}
#testimonial_section {
    border-radius: var(--radius-none, 0px);
    margin-top: -2px !important;
}

.testimonial_heading {
    font: 400 1rem/24px "Poppins";
    letter-spacing: 0;
    color: rgba(95, 136, 159, 1);
}

.testimonial_content_container {
    border-radius: 8px;
    overflow: visible;
    border: 1px solid;
    border-color: transparent;
    border-image: linear-gradient(to-bottom, rgb(57.79, 50.54, 100.07), rgb(20.15, 11.49, 33.14)) 1;
    background: linear-gradient(180deg, rgb(30, 22, 55) 0%, rgb(28, 18, 48) 75.17%);
    width: 48% !important;
    border-top: rgb(53 25 94) solid 1px !important;
    border-bottom: rgb(17, 11, 29) solid 1px !important;
    border-left: #170C24;
    border-right: #170C24;
}

.testimonial_video_container video {
    width: 100%;
    height: 100%;
}

.testimonial_video_container{
    border-radius: 8px !important;
    overflow: hidden !important;
}

.testimonial_video_play_button{
    height: 15.6%;
    width: 15.6%;
    position: absolute;
    top: 50%;
    left: 23%;
    transform: translate(-50%, -50%);
    z-index: 1; 
}
.testimonial_video_container #testimonial_video{
    padding: 0px !important;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.testimonial_primary_text {
    font: 400 1.5rem/36px "Poppins";
    letter-spacing: 0;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.testimonial_person_name {
    font: 600 16px/24px "Poppins";
    letter-spacing: 0;
    color:#91CCED;
}

.testimonial_person_description {
    font: 400 0.875rem/20px "Poppins";
    letter-spacing: 0;
    color: #FFFFFF;
}

.testimonial_person_img{
    padding-right: 25px !important;
}

#testimonial_dots_image-container {
    position: relative;
    overflow: visible;
    width: 103%;
    z-index: 1;
    top: 2.5em;
}

.testimonial-dots-image {
    width: 149px;
    height: 149px;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: -74.5px;
    margin-bottom: -74.5px;
}

.testimonial_content_resident{
    font-family: Poppins;
    font-size: 3rem;
    font-weight: 700;
    line-height: 58px;
    letter-spacing: 0em;
    text-align: center;
    padding: 32px 0px 64px 0px;
}

.testimonial_resident_text{
    color: #DB2349;
}

.testimonial_what_text{
    color: var(--color-white, #fff);
}

#testimonial_section .carousel-indicators {
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-bottom: -2rem; 
    border-radius: 100% !important;
    display: none;
  }

  #testimonial_section .carousel-control-next-icon{
    background-image: url('../images/carousel_next_arrow.svg');
    width: 4rem;
    height: 4rem;
    display: none !important;
}
  #testimonial_section .carousel-control-prev-icon{
    background-image: url('../images/carousel_prev_arrow.svg');
    width: 4rem;
    height: 4rem;
    display: none !important;
}

#testimonial_section .carousel-indicators .active {
background-color: white; 
}

.testimonial-card{
height: 28.125rem !important; 
overflow: hidden;
display: flex;
justify-content: space-between;
align-items: center;
}

.img_styles{
    width: 60px !important;
    height: 60px !important;
    border-radius: 1000px;
    border: 0.75px;
}
#testimonial_section .carousel-control-prev {
    width: 8% !important;
    left: -3.7% !important;
    opacity: 1;
}
#testimonial_section .carousel-control-next{
    width: 8% !important;
    right: -3.6% !important;
    opacity: 1;
} 
.testimonial_text_container{
    padding: 9.5%;
}

@media screen and (min-width: 1399px){
    .testimonial_text_container{
        padding: 14%;
        padding-left: 6% !important;
        padding-right: 6% !important;
    }  
}

@media screen and (max-width: 1399px) {
    .testimonial_vd_poster{
        height: 25.2em;
    }
}

@media screen and (max-width: 1199px) {
    
    .testimonial_primary_text {
        font: 1.25rem/30px "Poppins";
        letter-spacing: 0;
        -webkit-line-clamp: 4;
    }
    .testimonial_content_container {
        min-height: 19rem !important;
        border: none !important;
    }
    .testimonial_text_container{
        padding: 13% !important;
        padding-left: 6% !important;
        padding-right: 6% !important;
    }
    .testimonial_vd_poster{
        height: auto !important;
    }
}

@media screen and (max-width: 991px) {
    .testimonial_primary_text {
        font: 1.25rem/26px "Poppins";
        letter-spacing: 0;
        -webkit-line-clamp: 3;
    } 
    .testimonial_content_container {
        min-height: 15rem !important;
    }
    .testimonial_text_container{
        padding: 8% !important;
        padding-left: 6% !important;
        padding-right: 6% !important;
    }
}

@media screen and (max-width: 767px) {
    .testimonial_content_resident {
        font-size: 1.425rem !important;
    }
    .testimonial_content_container {
        min-height: 11rem !important;
    }
    .testimonial_text_container{
        padding: 8% !important;
        padding-left: 6% !important;
        padding-right: 6% !important;
    }
    .testimonial_primary_text {
        font: 1rem/24px "Poppins";
        letter-spacing: 0;
        -webkit-line-clamp: 3;
    } 
    .testimonial_person_description {
        font: 0.775rem/20px "Poppins";
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: normal;
    }
    .img_styles{
        width: 40px !important;
        height: 40px !important;
    }
    .testimonial_person_img{
        margin-top : 5px !important;
    }
    .testimonial_person_name{
        margin-top: 5px !important;
    }
    .testimonial-card{
        margin-top: -8rem !important;
        margin-bottom: -6rem !important;
    }
    #testimonial_section .carousel-control-prev {
        top: -6% !important;
    }
    #testimonial_section .carousel-control-next{
        top:-6% !important;
    } 
    .testimonial_video_play_button{
        height: 15.6%;
        width: 15.6%;
        top: 43%;
        left: 23%; 
    }
}

@media screen and (max-width: 575px) {
    .testimonial_person_img{
        margin-top: 0.8rem !important;
    }
    #testimonial_section .carousel-control-prev {
        width: 7% !important;
        left: -2.5% !important;
        top: -10% !important;
    }
    #testimonial_section .carousel-control-next{
        width: 7% !important;
        right: -2.5% !important;
        top: -10% !important;
    }
    .testimonial_content_container {
        width: 47% !important;
    }
}

@media screen and (max-width: 541px) {
    .testimonial_content_resident {
        font-size: 1.5rem !important;
        padding: 25px 0px 35px 0px !important;
    }
    .testimonial_primary_text {
        font: 0.9rem/22px "Poppins";
        letter-spacing: 0;
    }
    .testimonial_person_img{
        margin-top: 0.5rem !important;
    }
    #testimonial_section .carousel-indicators [data-bs-target] {
        width: 10px !important;
        height: 10px !important;
      }
    .testimonial_person_name {
        font:0.8rem/20px "Poppins";
    }
    .testimonial_person_description {
        font:0.8rem/20px "Poppins";
    }
    .testimonial_content_container {
        min-height: 10rem !important;
    }
    .testimonial_primary_text {
        font: 1rem/20px "Poppins";
        letter-spacing: 0;
        -webkit-line-clamp: 3;
    } 
}
 
@media screen and (max-width:495px) {
    .testimonial_content_container {
        min-height: 8.7rem !important;
    }
    .testimonial_text_container{
        padding: 7% !important;
        padding-left: 6% !important;
        padding-right: 6% !important;
    }
}

@media (min-width: 430px) {
    #testimonial_section .mobile-carousel {
      display: none;
    }
    #testimonial_section .desktop-carousel {
      display: block;
    }
 }

@media screen and (max-width:430px) {
    .testimonial_content_resident{
        font-family: Poppins;
        font-size: 1.5rem !important;
        font-weight: 700;
        line-height: 28.8px !important;
        padding: 40px 20px 40px 20px !important;
    }
    .testimonial_primary_text {
        font: 400 1.2rem/28px "Poppins";
        -webkit-line-clamp: 4;
    }
    .testimonial_person_name {
        font: 600 17px/9.18px "Poppins" !important;
        margin-top: 23px !important;
    }
    .testimonial_person_description {
        margin-top: 10px !important;
    }
    .testimonial_content_container {
        width: 94% !important;
        padding:0px !important;
        border-radius: 2.39px;
    }
    .testimonial_text_container{
        padding: 50px !important;
    }
    #testimonial_section{
        padding: 33.6px 8.2px 33.6px 20.2px !important;
    }
    #testimonial_section .carousel-control-prev {
        width: 9% !important;
        left: -3% !important;
        top: 3% !important;
    }
    #testimonial_section .carousel-control-next{
        width: 9% !important;
        right: -1.4% !important;
        top: 3% !important;
    }
    .testimonial_person_img{
        margin-top: 15px !important;
        padding-right: 20px !important;
    }
    #testimonial_section .desktop-carousel {
        display: none;
    }
    #testimonial_section .mobile-carousel {
    display: block;
    }
    .img_styles{
        width: 60px !important;
        height: 60px !important;
        border-radius: 4968.43px !important;
        border: 0.37px !important;
    }
     .testimonial_video_container #testimonial_video{
        padding: 0px !important;
    }
    .testimonial-card {
        margin-top: -3rem !important;
        margin-bottom: -4rem !important;
    }
    .testimonial_video_play_button{
        height: 15.6%;
        width: 15.6%;
        position: absolute;
        top: 50%;
        left: 45%;
        transform: translate(-50%, -50%);
        z-index: 1; 
    }
    .testimonial_content_container {
        width: 97%  !important;
    }
}

@media screen and (max-width:430px) {
    .testimonial_text_container{
        padding: 20px !important;
        padding-right: 0.5% !important;
    }
    .testimonial_primary_text {
        font: 400 1.2rem/28px "Poppins";
        -webkit-line-clamp: unset !important;
        padding: 5%;
        padding-left: 3% !important;
    }
    .testimonial_content_container{
        height: 22em !important;
    }
    .testimonial_vd_poster{
        height: 22em !important;
    }
    .testimonial_person_details{
        margin-top: -1% !important;
        padding-left: 3% !important;
    }
}

@media screen and (max-width:418px) {
    .testimonial_primary_text {
        padding: 3% !important;
        padding-right: 6% !important;
    }
}